<template>
  <div class="transit">
    <div class="longdo-map-content">
      <longdo-map @load="loadedLongdoMap" />
    </div>
  </div>
</template>

<script>
export default {
  name: 'Transit',
  data () {
    return {
      map: null,
      initialZoom: 3,
      zoomRange: {
        min: 2,
        max: 5
      },
      stationMarkerSize: [
        {},
        {
          large: 9,
          small: 3
        },
        {
          large: 16,
          small: 12
        },
        {
          large: 31,
          small: 24
        },
        {
          large: 66,
          small: 47
        }
      ],
      stationMarkerList: []
    }
  },
  methods: {
    loadedLongdoMap (map) {
      const self = this
      self.map = map
      self.map.location({
        lat: 0,
        lon: 0
      })
      self.map.Layers.setBase(window.longdo.Layers.BLANK)
      self.map.zoomRange(self.zoomRange)
      self.map.zoom(self.initialZoom)
      self.map.Ui.DPad.visible(false)
      self.map.Ui.Geolocation.visible(false)
      self.map.Ui.LayerSelector.visible(false)
      self.map.Ui.Toolbar.visible(false)
      self.map.Ui.Zoombar.visible(false)
      self.map.Ui.Scale.visible(false)

      window.addEventListener('resize', () => {
        self.map.resize()
      })

      self.map.Event.bind('zoom', () => {
        const zoom = self.map.zoom()
        self.stationMarkerList.forEach(stationMarker => {
          const a = stationMarker.element().querySelector('.station-marker')
          const initialSize = self.stationMarkerSize[self.initialZoom - 1][stationMarker.stationData.isInterchange ? 'large' : 'small']
          const newSize = self.stationMarkerSize[zoom - 1][stationMarker.stationData.isInterchange ? 'large' : 'small']
          const scale = newSize * 1.0 / initialSize
          a.style.transform = `scale(${scale})`
        })
      })

      const transitMapImage = new window.longdo.Rectangle({
        lat: 85.5,
        lon: 180
      }, {
        lat: -85.5,
        lon: -180
      }, {
        texture: self.baseUrl + 'img/transit-map.jpg',
        weight: 0
      })
      self.map.Overlays.add(transitMapImage)

      self.drawStation()
    },
    async drawStation () {
      const self = this
      const result = await self.api.getStationTransitMap()
      const zoom = self.map.zoom()

      result.data.forEach(station => {
        const popupOption = {
          title: station.name.th
        }
        const size = self.stationMarkerSize[zoom - 1][station.isInterchange ? 'large' : 'small']

        const stationMarker = new window.longdo.Marker(station.location, {
          icon: {
            html: `
              <div class="station-marker" style="width: ${size}px; height: ${size}px;">
                <div class="outter" style="background-color: ${station.borderColor};"></div>
                <div class="inner"></div>
              </div>
            `,
            offset: {
              x: size / 2,
              y: size / 2
            }
          },
          popup: popupOption,
          clickable: true
        })
        stationMarker.stationData = station
        self.stationMarkerList.push(stationMarker)
        self.map.Overlays.add(stationMarker)
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.transit {
  width: 100%;
  height: 100%;

  .longdo-map-content {
    width: 100%;
    height: 100%;
  }
}
</style>

<style lang="scss">
.ldmap_tile_offworld {
  background-color: rgb(255, 255, 255) !important;
}
</style>
